import * as React from 'react'
import { Link, usePage } from '@inertiajs/react'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import { cn } from '@/lib/utils'
import type { NavItem } from '@/types'
import { motion, AnimatePresence } from 'framer-motion'
import useLocalizedValue from '@/hooks/use-localized-value'

export default function Desktop({ navData }: { navData: { [key: string]: NavItem[] } }) {
  const { url } = usePage()
  const [activeItem, setActiveItem] = React.useState<NavItem | null>(null)

  React.useEffect(() => {
    const newActiveItem =
      Object.values(navData)
        .flat()
        .find((item) => item.href === url) || null
    setActiveItem(newActiveItem)
  }, [url, navData])

  return (
    <NavigationMenu>
      <NavigationMenuList className="gap-x-2.5">
        {Object.entries(navData)
          .sort(
            ([, a], [, b]) =>
              Math.min(...a.map((item) => item.order)) - Math.min(...b.map((item) => item.order))
          )
          .map(([key, items]) =>
            key === 'default' ? (
              items.map((link) => <DefaultMenuItem key={link.href} link={link} url={url} />)
            ) : (
              <GroupMenuItem
                key={key}
                groupKey={key}
                items={items}
                url={url}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            )
          )}
      </NavigationMenuList>
    </NavigationMenu>
  )
}

function DefaultMenuItem({ link, url }: { link: NavItem; url: string }) {
  return (
    <NavigationMenuItem>
      <NavigationMenuLink asChild>
        <Link
          href={link.href}
          className={
            navigationMenuTriggerStyle() +
            cn(
              'hover:bg-secondary hover:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:hover:text-primary',
              url === link.href ? 'text-primary dark:text-primary' : '',
              link.href === '/top-up' ? 'md:max-lg:hidden lg:flex' : ''
            )
          }
          onClick={(e) => {
            if (url === link.href) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          prefetch
        >
          {useLocalizedValue(link.name)}
        </Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  )
}

function GroupMenuItem({
  groupKey,
  items,
  url,
  activeItem,
  setActiveItem,
}: {
  groupKey: string
  items: NavItem[]
  url: string
  activeItem: NavItem | null
  setActiveItem: React.Dispatch<React.SetStateAction<NavItem | null>>
}) {
  const [hoveredItem, setHoveredItem] = React.useState<NavItem | null>(null)

  return (
    <NavigationMenuItem
      onMouseEnter={() => {
        if (!activeItem || activeItem.group !== groupKey) {
          const groupItem = items.find((item) => item.group === groupKey)
          if (groupItem) setActiveItem(groupItem)
        }
      }}
    >
      <NavigationMenuTrigger
        className={cn(
          'hover:bg-secondary hover:text-primary data-[state=open]:bg-secondary data-[state=open]:text-primary supports-[backdrop-filter]:bg-transparent supports-[backdrop-filter]:hover:bg-white/30 supports-[backdrop-filter]:data-[active]:bg-white/30 supports-[backdrop-filter]:data-[state=open]:bg-white/30 dark:text-gray-200 dark:hover:bg-accent/50 dark:data-[state=open]:bg-accent/50 dark:data-[active]:text-primary dark:data-[state=open]:text-primary',
          items.some((item) => item.href === url)
            ? 'text-primary dark:text-primary'
            : 'text-foreground'
        )}
      >
        {useLocalizedValue(items.find((item) => item.group === groupKey)?.parent || {})}
      </NavigationMenuTrigger>
      <NavigationMenuContent>
        <ul
          className={cn(
            'w-[300px] grid-flow-col flex-col gap-x-4 gap-y-1.5 p-4 lg:grid',
            items.length > 3
              ? 'grid-rows-3 lg:w-[700px] lg:grid-cols-[.85fr_1fr_1fr]'
              : 'grid-rows-2 lg:w-[500px] lg:grid-cols-[.75fr_1fr]'
          )}
        >
          <motion.li className="row-span-3 mb-4 hidden lg:mb-0 lg:flex" layout>
            <NavigationMenuLink asChild>
              <Link
                href={(hoveredItem || activeItem)?.href || ''}
                className="group relative flex h-full w-full select-none items-center p-0 no-underline outline-none"
                prefetch
              >
                <AnimatePresence>
                  <motion.img
                    key={(hoveredItem || activeItem)?.href}
                    className={cn('absolute inset-0 h-full w-full rounded-xl object-fill')}
                    src={(hoveredItem?.image || activeItem?.image) ?? ''}
                    alt={useLocalizedValue((hoveredItem || activeItem)?.name || {}) || ''}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.3 } }}
                    transition={{ duration: 0.4, ease: 'easeOut' }}
                  />
                </AnimatePresence>
                <motion.div
                  className="absolute inset-x-0 bottom-4 mx-auto w-[140px] min-w-fit rounded-xl bg-white/5 px-4 py-0.5 text-center backdrop-blur-md"
                  initial={{ opacity: 0.2, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.35, ease: 'easeOut' }}
                >
                  <span className="z-10 whitespace-nowrap text-[16px] font-semibold text-white">
                    {useLocalizedValue((hoveredItem || activeItem)?.name || items[0].name)}
                  </span>
                </motion.div>
              </Link>
            </NavigationMenuLink>
          </motion.li>
          {items.map((listItem) => (
            <ListItem
              key={listItem.href}
              item={listItem}
              url={url}
              onMouseEnter={() => setHoveredItem(listItem)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {useLocalizedValue(listItem.description)}
            </ListItem>
          ))}
        </ul>
      </NavigationMenuContent>
    </NavigationMenuItem>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'> & {
    item: NavItem
    url: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
  }
>(({ className, item, url, children, onMouseEnter, onMouseLeave, ...props }, ref) => {
  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (url === item.href) {
      e.preventDefault()
      e.stopPropagation()
    }
    props.onClick?.(e as React.MouseEvent<HTMLAnchorElement>)
  }
  return (
    <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <NavigationMenuLink asChild>
        <Link
          href={item.href}
          className={cn(
            'group block select-none space-y-1 rounded-xl p-3 leading-none no-underline outline-none transition-colors duration-300 hover:bg-muted dark:hover:bg-background',
            className
          )}
          prefetch
          onClick={handleClick}
        >
          <div
            className={cn(
              'text-sm font-medium leading-none',
              item.href === url ? 'text-primary dark:text-primary' : 'text-foreground'
            )}
          >
            {useLocalizedValue(item.name)}
          </div>
          <p className="line-clamp-2 text-xs leading-snug text-body">{children}</p>
        </Link>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = 'ListItem'
