'use client'

import { cn } from '@/lib/utils'
import React from 'react'
import WordRotate from '@/components/ui/word-rotate'
import { motion } from 'framer-motion'
import { Head } from '@inertiajs/react'

interface InteractiveHeroImageProps extends React.HTMLAttributes<HTMLDivElement> {
  sentences: string[]
  image: string
}

export function InteractiveHeroImage({
  sentences,
  image,
  className,
}: InteractiveHeroImageProps): React.ReactElement {
  return (
    <>
      <Head>
        <link rel="preload" href={image} as="image" />
      </Head>
      <section className="relative flex">
        <motion.div
          className="absolute top-[10px] h-[120px] w-[200px] rounded-full bg-gradient-to-t from-[#f00028] to-[#0046b4] blur-[140px] [--end-end:calc(100%-360px)] [--end-start:20px] md:top-[25px] md:h-[288px] md:w-[320px] md:[--end-end:calc(100%-365px)] md:[--end-start:45px] lg:top-[30px] lg:h-[360px] lg:w-[400px] lg:[--end-end:calc(100%-500px)] lg:[--end-start:140px]"
          animate={{
            insetInlineEnd: ['var(--end-start)', 'var(--end-end)'],
            rotate: [-90, 0, 90, 0, -90],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          }}
        />
        <img
          src={image}
          alt=""
          className={cn(
            'z-10 aspect-[4/2] h-[220px] w-full rounded-2xl object-cover sm:h-[450px] md:rounded-[28px] lg:rounded-[40px] rtl:scale-x-[-1]',
            className
          )}
          loading="lazy"
          fetchPriority="high"
        />
        <div className="absolute inset-0 z-20 flex items-center justify-start">
          <WordRotate
            words={sentences}
            className="flex flex-col ps-8 text-3xl font-bold tracking-[-0.02em] text-white dark:text-primary sm:text-5xl md:ps-20"
          />
        </div>
      </section>
    </>
  )
}
