import React, { useEffect, useState } from 'react'
import { SimpleSection } from '@/components/custom/simple-section'
import useLocalizedValue from '@/hooks/use-localized-value'
import { Block } from '@/hooks/use-block-renderer'
import RCarousel from '@/components/custom/carousel'
import QuickAccessLinks from './sections/QuickAccessLinks'
import TelecomPlansGrid1 from '@/components/custom/dynamic-island'
import { InteractiveHeroImage } from './custom/interactive-hero-image'
import SupportSection from './custom/support-section'
import { SplitSection } from './custom/split-section'
import NewsSection from './sections/news-section'
import ServiceSection from './sections/service-section'
import TariffSection from './custom/tariff-section'
import { BundleTab } from '@/types'
import TabbedSection from './custom/tabbed-section'
import RichContentSection from './custom/rich-content-section'
import FAQSection from './sections/faqs-section'
import { convertComponentAsClassName } from '@/lib/utils'
import { Head } from '@inertiajs/react'

interface BlockComponentProps {
  data: Block['data']
  component?: null | string
}

const CarouselBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <RCarousel
    images={data.media}
    className="w-full"
    imageClass="object-fit w-full aspect-[4/2] h-auto max-h-[450px] rounded-2xl md:rounded-[28px] lg:rounded-[40px]"
  />
)

const HeroBlock: React.FC<BlockComponentProps> = ({ data }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => setImageLoaded(true)
    img.src = data.media
  }, [data.media])

  const title = useLocalizedValue(data.title || {})
  const subtitle = useLocalizedValue(data.subtitle || {})

  return (
    <>
      <Head>
        <link rel="preload" href={data.media} as="image" />
      </Head>
      <SimpleSection top={data.media_order === 1} title={title} subtitle={subtitle}>
        <div className="relative mx-auto w-full">
          <div
            className={`absolute inset-0 animate-preloader rounded-2xl border border-border bg-gray-100 md:rounded-[28px] lg:rounded-[40px] ${
              imageLoaded ? 'hidden' : 'block'
            } transition-all`}
          />
          <img
            src={data.media || '/placeholder.svg'}
            alt={title || ''}
            className={`aspect-[4/2] h-auto max-h-[450px] w-full rounded-2xl object-cover md:rounded-[28px] lg:rounded-[40px] ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-300 ease-in-out`}
            loading="eager"
            fetchPriority="high"
            onLoad={() => setImageLoaded(true)}
          />
        </div>
      </SimpleSection>
    </>
  )
}

const QuickAccessLinksBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <QuickAccessLinks links={data.links} />
)

const BundleSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <>
    {data.is_published == 1 && (
      <TelecomPlansGrid1 bundles={data.bundles} title={data.title} subtitle={data.subtitle} />
    )}
  </>
)

const SupportSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <SupportSection
    items={data?.support || []}
    title={useLocalizedValue(data.title || {}) || ''}
    subtitle={useLocalizedValue(data.subtitle || {}) || ''}
  />
)

const SplitSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <SplitSection items={data.items} title={data.title} subtitle={data.subtitle} media={data.media} />
)

const InteractiveHeroImageBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <InteractiveHeroImage
    sentences={data.sentences.map((item: any) => {
      return useLocalizedValue(item.sentence)
    })}
    image={data.media}
  />
)

const NewsSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <NewsSection title={data.title} subtitle={data.subtitle} articles={data.articles} />
)

const ServiceSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <ServiceSection title={data.title} subtitle={data.subtitle} services={data.services} />
)

const TariffSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <TariffSection tabs={data.tariffs as BundleTab[]} />
)

const PlansSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <TariffSection tabs={data.plans} isSimple={data.is_simple} />
)

const TabbedSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <TabbedSection tabs={data.tabs} />
)

const RichContentSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <>
    {data.is_published == 1 && (
      <RichContentSection content={data.richContent} className={data.title ? '' : 'px-0'} />
    )}
  </>
)

const FaqsSectionBlock: React.FC<BlockComponentProps> = ({ data }) => (
  <>
    {data.is_published == 1 && (
      <FAQSection
        questions={data.questions}
        icon={data.icon}
        adjustGap={data.title ? false : true}
      />
    )}
  </>
)

const MultiOptionSection: React.FC<BlockComponentProps> = ({ data, component }) => (
  <>
    <TabbedSection tabs={data.tabs} className={component} />
  </>
)

const blockComponents: { [key: string]: React.FC<BlockComponentProps> } = {
  hero_image: HeroBlock,
  carousel: CarouselBlock,
  quick_access_links: QuickAccessLinksBlock,
  bundle_section: BundleSectionBlock,
  hero_image_interactive: InteractiveHeroImageBlock,
  support_section: SupportSectionBlock,
  split_section: SplitSectionBlock,
  news_section: NewsSectionBlock,
  service_section: ServiceSectionBlock,
  tariff_section: TariffSectionBlock,
  plans_section: PlansSectionBlock,
  tabbed_section: TabbedSectionBlock,
  rich_content_section: RichContentSectionBlock,
  multi_option_section: MultiOptionSection,
  faqs_section: FaqsSectionBlock,
}

interface BlockRendererProps {
  blocks: Block[]
  customComponents?: { [key: string]: React.FC<BlockComponentProps> }
}

export const BlockRenderer: React.FC<BlockRendererProps> = ({ blocks, customComponents = {} }) => {
  const allComponents = { ...blockComponents, ...customComponents }

  return (
    <>
      {blocks.map((block, index) => {
        const BlockComponent = allComponents[block.type]
        return BlockComponent ? (
          <BlockComponent
            key={index}
            data={block.data}
            component={convertComponentAsClassName(block.component)}
          />
        ) : null
      })}
    </>
  )
}
