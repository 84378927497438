import * as React from 'react'
import { Head } from '@inertiajs/react'
import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Carousel, CarouselContent, CarouselItem } from '@/components/ui/carousel'
import { cn } from '@/lib/utils'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import Autoplay from 'embla-carousel-autoplay'
import Fade from 'embla-carousel-fade'

interface RCarouselProps {
  images: string[]
  className?: string
  imageClass?: string
}

const DotIndicator = React.memo(
  ({
    isActive,
    onClick,
    className,
  }: {
    isActive: boolean
    onClick: () => void
    className?: string
  }) => (
    <Button
      variant="ghost"
      size="sm"
      className={cn(
        'mx-1 size-2 rounded-full p-0 lg:size-2.5',
        isActive
          ? 'bg-white hover:bg-white dark:bg-primary'
          : 'bg-white/20 hover:bg-white/40 dark:bg-primary/40',
        className
      )}
      onClick={onClick}
    />
  )
)

DotIndicator.displayName = 'DotIndicator'

export default function RCarousel({ images, className, imageClass }: RCarouselProps) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [api, setApi] = React.useState<any>()
  const [imagesLoaded, setImagesLoaded] = React.useState<boolean[]>([])

  React.useEffect(() => {
    if (!api) return

    const onSelect = () => setCurrentSlide(api.selectedScrollSnap())
    api.on('select', onSelect)

    return () => {
      api.off('select', onSelect)
    }
  }, [api])

  React.useEffect(() => {
    const imagePromises = images.map((src) => {
      return new Promise((resolve) => {
        const img = new Image()
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
        img.src = src
      })
    })

    Promise.all(imagePromises).then((loadStates) => {
      setImagesLoaded(loadStates as boolean[])
    })
  }, [images])

  const handleDotClick = React.useCallback(
    (index: number) => {
      if (api) {
        api.scrollTo(index)
      }
    },
    [api]
  )

  const isRTL = document.dir === 'rtl'

  const autoplayPlugin = React.useMemo(
    () =>
      Autoplay({
        delay: 5000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
        rootNode: (emblaRoot) => emblaRoot.parentElement,
      }),
    []
  )

  return (
    <>
      <Head>
        {images.slice(0, 3).map((src, index) => (
          <link key={`preload-image-${index}`} rel="preload" href={src} as="image" />
        ))}
      </Head>
      <section className={cn('relative mx-auto', className)}>
        <Carousel
          opts={{
            dragFree: false,
            loop: true,
            direction: isRTL ? 'rtl' : 'ltr',
          }}
          plugins={[autoplayPlugin, Fade()]}
          setApi={setApi}
          className="w-full"
          dir={isRTL ? 'rtl' : 'ltr'}
        >
          <CarouselContent>
            {images.map((src, index) => (
              <CarouselItem key={index}>
                <Card className="rounded-none border-none bg-transparent shadow-none">
                  <CardContent className="relative flex items-center justify-center p-0">
                    <div
                      className={cn(
                        'absolute inset-0 z-0 animate-preloader rounded-2xl bg-gray-200 md:rounded-[28px] lg:rounded-[40px]',
                        imagesLoaded[index] ? 'hidden' : 'block',
                        'transition-all'
                      )}
                    />
                    <img
                      src={src || '/placeholder.svg'}
                      alt={`Slide ${index + 1}`}
                      loading={index === 0 ? 'eager' : 'lazy'}
                      className={cn(
                        imageClass || 'h-full w-full object-cover',
                        'transition-opacity duration-500 ease-in-out',
                        imagesLoaded[index] ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
        <div
          className={
            'absolute inset-x-0 bottom-2 z-10 flex justify-center ' +
            `${imagesLoaded.length > 0 ? 'visible' : 'hidden'}`
          }
        >
          <div className="flex items-center justify-between space-x-2 rounded-full bg-black/20 px-3 py-1.5 backdrop-blur-sm dark:bg-black rtl:space-x-reverse">
            <Button
              variant="ghost"
              size="icon"
              className="size-4 shrink-0 rounded-full text-white hover:bg-white/30 hover:text-white hover:backdrop-blur-sm dark:text-primary dark:hover:bg-primary/20"
              onClick={() => api?.scrollPrev()}
            >
              <ChevronLeft className="size-3 lg:size-4 rtl:rotate-180" />
              <span className="sr-only">Previous slide</span>
            </Button>
            <div className="flex flex-1 items-center justify-center">
              {images.map((_, index) => (
                <DotIndicator
                  key={index}
                  isActive={currentSlide === index}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="size-4 shrink-0 rounded-full text-white hover:bg-white/30 hover:text-white hover:backdrop-blur-sm dark:text-primary dark:hover:bg-primary/20"
              onClick={() => api?.scrollNext()}
            >
              <ChevronRight className="size-3 lg:size-4 rtl:rotate-180" />
              <span className="sr-only">Next slide</span>
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}
