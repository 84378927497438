'use client'

import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from '@/components/ui/button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils'

import { LanguageSensitiveLazySvg, LazySvg } from '@/components/custom/lazy-svg'
import { SimpleSection } from '@/components/custom/simple-section'
import useLocalizedValue from '@/hooks/use-localized-value'
import { Translatable } from '@/types'
import WordPullUp from '../ui/word-pull-up'

interface ItemDetail {
  title: Translatable<string>
  price: string
  validity: Translatable<string>
  description: Translatable<string>
  type: string
}

interface Item {
  id: string
  name: Translatable<string>
  icon: string
  details: ItemDetail[]
}

interface SupportSectionProps {
  items: Item[]
  title: string
  subtitle: string
}

export default function SupportSection({
  items,
  title,
  subtitle,
}: SupportSectionProps): JSX.Element {
  const [activeItem, setActiveItem] = useState<Item | null>(items[0])

  const handleItemClick = (item: Item) => {
    setActiveItem(item)
  }

  return (
    <SimpleSection top={false} title={title} subtitle={subtitle}>
      <Drawer shouldScaleBackground={false} autoFocus={false}>
        <DrawerTrigger asChild>
          <div className="lg:grid-cols-max grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-flow-col lg:grid-cols-4">
            {items.map((item: any, index: any) => (
              <motion.div
                initial={{
                  opacity: 0,
                  y: index < 2 ? '-50%' : '50%',
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.1,
                  ease: 'easeOut',
                }}
                viewport={{ once: true }}
                key={index}
                role="button"
                className={cn(
                  'group flex h-[140px] w-[158px] cursor-pointer flex-col items-center justify-around rounded-3xl border border-border p-6 text-center text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white'
                  //   index == Object.keys(items).length - 1 ? 'col-span-2 lg:col-span-1' : 'w-[158px]'
                )}
                onClick={() => handleItemClick(item)}
              >
                <LanguageSensitiveLazySvg
                  name={item.icon}
                  className="size-10 text-primary transition-colors group-hover:text-white dark:text-primary"
                />
                <div className="text-sm font-medium">{useLocalizedValue(item.name)}</div>
              </motion.div>
            ))}
          </div>
        </DrawerTrigger>

        <DrawerContent className="h-[70svh] max-h-[85vh]">
          <div className="sm:hidden">
            <Select
              value={activeItem?.id}
              onValueChange={(value) =>
                setActiveItem(items.find((item) => item.id === value) || null)
              }
            >
              <SelectTrigger className="mx-auto mt-4 h-12 w-full max-w-md rounded-xl border-border">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                {items.map((item) => (
                  <SelectItem key={item.id} value={item.id}>
                    {useLocalizedValue(item.name)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Tabs
            defaultValue={useLocalizedValue(activeItem?.name ?? {}) || ''}
            className="mt-2 hidden sm:block"
          >
            <TabsList className="flex h-fit gap-4 sm:h-full md:gap-6">
              {items.map((item) => (
                <TabsTrigger
                  key={item.id}
                  value={item.id}
                  data-state={activeItem?.id === item.id ? 'active' : 'inactive'}
                  className={cn(
                    'group inline-flex h-[110px] w-[120px] cursor-pointer items-center justify-center whitespace-nowrap rounded-3xl border border-border p-4 text-center text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white',
                    'data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow'
                  )}
                  onClick={() => setActiveItem(item)}
                >
                  <div className="flex flex-col items-center gap-2 sm:gap-4">
                    <LanguageSensitiveLazySvg
                      name={item.icon}
                      className={cn(
                        'size-6 transition-colors sm:size-8',
                        'text-primary group-hover:text-primary-foreground',
                        'dark:text-primary',
                        'group-data-[state=active]:text-primary-foreground'
                      )}
                    />
                    <div className="text-wrap text-xs font-semibold capitalize">
                      {useLocalizedValue(item.name)}
                    </div>
                  </div>
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <ScrollArea className="max-h-[50vh h-full">
            <div className="mx-auto h-full w-full max-w-4xl pb-6 pt-8">
              <AnimatePresence mode="wait">
                {activeItem &&
                  activeItem.details.length > 0 &&
                  activeItem.id !== 'credit-share' && (
                    <motion.div
                      key={activeItem.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2 }}
                      className="grid grid-cols-1 justify-items-center gap-4"
                    >
                      {activeItem.details.map((detail, index) => (
                        <div
                          key={index}
                          className={cn(
                            'mx-auto flex w-full max-w-sm flex-col rounded-xl bg-card p-4 text-card-foreground shadow'
                          )}
                        >
                          <div className="mx-auto flex w-full max-w-sm items-center justify-between">
                            <div className="flex flex-col items-start justify-center">
                              <span className="text-2xl font-semibold">{detail.title.en}</span>
                              <p className="text-sm text-muted-foreground">{detail.validity.en}</p>
                            </div>
                            <div className="inline-flex items-center">
                              <LazySvg name="currency-alt" className="size-6 text-primary" />
                              <p className="text-4xl font-bold text-primary">{detail.price}</p>
                            </div>
                          </div>
                          <span className="text-wrap text-xs text-body">
                            {detail.description.en}
                          </span>
                        </div>
                      ))}
                    </motion.div>
                  )}
                {activeItem && activeItem.id === 'credit-share' && (
                  <motion.div
                    key={activeItem.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                    className="mx-auto flex w-full max-w-sm flex-col gap-1 rounded-xl bg-card p-4 text-card-foreground shadow"
                  >
                    <div className="mx-auto flex w-full max-w-sm items-center justify-between space-x-2">
                      <span className="text-sm font-medium text-body">Max Transactions: </span>
                      <span className="text-sm font-semibold text-primary/85">5/Day</span>
                    </div>
                    <div className="mx-auto flex w-full max-w-sm items-center justify-between space-x-2">
                      <span className="text-sm font-medium text-body">Fee: </span>
                      <span className="text-sm font-semibold text-primary/85">
                        1.00 AFN/Transaction
                      </span>
                    </div>
                    <div className="mx-auto flex w-full max-w-sm items-center justify-between space-x-2">
                      <span className="text-sm font-medium text-body">Transaction Amount: </span>
                      <span className="text-sm font-semibold text-primary/85">
                        From 5.00 AFN to 50.00 AFN
                      </span>
                    </div>
                    <span className="text-sm font-medium text-body">Dail *123# </span>
                  </motion.div>
                )}
                {activeItem &&
                  activeItem.id !== 'credit-share' &&
                  activeItem.details.length === 0 && (
                    <div className="mt-6 flex flex-col items-center justify-center">
                      <WordPullUp words="Coming Soon!" className="font-semibold text-foreground" />
                    </div>
                  )}
              </AnimatePresence>
            </div>
          </ScrollArea>

          <DrawerFooter className="mx-auto w-full max-w-md">
            <DrawerClose asChild>
              <Button variant="secondary" className="focus:border-0">
                Close
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </SimpleSection>
  )
}
