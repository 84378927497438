'use client'

import { motion, AnimatePresence } from 'framer-motion'
import useLocalizedValue from '@/hooks/use-localized-value'
import { Article, PaginatedResponse } from '@/types'
import NewsCard from '@/components/custom/news-card'
import { usePage, Link, router } from '@inertiajs/react'
import { LazySvg } from '@/components/custom/lazy-svg'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { cn } from '@/lib/utils'
import GlobalImagePreloadEffect from '@/components/custom/global-image-preload-effect'

export default function Index() {
  const { pinnedArticle, articles } = usePage().props

  const handlePageChange = (url: string) => {
    router.visit(url, {
      only: ['articles'],
      preserveScroll: true,
      replace: true,
    })
  }

  return (
    <>
      <GlobalImagePreloadEffect />
      <div className="flex flex-col items-center justify-center gap-12">
        {pinnedArticle && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: '-50px' }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <FeaturedArticle article={pinnedArticle} />
          </motion.div>
        )}
        <AnimatePresence mode="wait">
          <motion.div
            key={articles.current_page}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="grid auto-cols-max grid-cols-1 gap-6 px-12 sm:grid-cols-2 sm:px-0 md:gap-10 xl:grid-cols-3"
          >
            {articles.data.map((article, index) => (
              <motion.div
                key={article.id}
                initial={{ opacity: 0, y: -20 }}
                viewport={{ once: true, margin: '-10px' }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <NewsCard
                  id={article.id}
                  title={useLocalizedValue(article.title) || ''}
                  excerpt={useLocalizedValue(article.excerpt) || ''}
                  media={useLocalizedValue(article.media) || ''}
                  publishedAt={article.published_at}
                />
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
        <NewsPagination links={articles.links} onPageChange={handlePageChange} />
      </div>
    </>
  )
}

function FeaturedArticle({ article }: { article: Article }) {
  return (
    <div className="relative rounded-[28px] bg-background/50 backdrop-blur transition-shadow duration-300 hover:shadow-md">
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="w-full md:w-2/3">
          <img
            src={useLocalizedValue(article.media) || ''}
            alt={useLocalizedValue(article.title) || ''}
            className="h-[250px] w-full rounded-[28px] object-cover md:h-[409px]"
          />
        </div>
        <div className="group relative px-6 py-4 md:w-1/3 md:py-4">
          {/* <div className="absolute inset-0 bg-gradient-to-l from-white/100 to-transparent"></div> */}

          <h2 className="line-clamp-2 text-2xl font-semibold leading-tight tracking-tighter text-foreground md:text-3xl">
            {useLocalizedValue(article.title)}
          </h2>
          <p className="mt-2 line-clamp-3 text-sm text-body md:line-clamp-[8]">
            {useLocalizedValue(article.excerpt)}
          </p>
          <div className="mt-4 px-2 py-4 md:absolute md:inset-x-0 md:bottom-0 md:right-4">
            <Link
              prefetch
              href={`/news/${article.id}`}
              className="group inline-flex w-full items-center justify-end text-sm"
            >
              <div className="flex items-center space-x-2 text-body transition-all duration-300 group-hover:text-primary">
                <span className="">Read More</span>
                <LazySvg name="arrow-right" className="size-3 group-hover:animate-bounce-x" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function NewsPagination({
  links,
  onPageChange,
}: {
  links: PaginatedResponse<Article>['links']
  onPageChange: (url: string) => void
}) {
  const { current_page, total } = usePage().props.articles

  if (links.length <= 3) return null // Don't show pagination for 1 page

  const pageLinks = links.slice(1, -1) // Remove first (prev) and last (next) items
  const totalPages = pageLinks.length

  const maxVisiblePages = 5 // Maximum number of page numbers to show

  let visibleLinks: typeof pageLinks = []

  if (totalPages <= maxVisiblePages) {
    visibleLinks = pageLinks
  } else {
    // Always include first page
    visibleLinks.push(pageLinks[0])

    if (current_page <= 3) {
      // Near the start
      visibleLinks = visibleLinks.concat(pageLinks.slice(1, 4))
      visibleLinks.push({ url: null, label: '...', active: false })
    } else if (current_page >= totalPages - 2) {
      // Near the end
      visibleLinks.push({ url: null, label: '...', active: false })
      visibleLinks = visibleLinks.concat(pageLinks.slice(-3))
    } else {
      // Somewhere in the middle
      visibleLinks.push({ url: null, label: '...', active: false })
      visibleLinks = visibleLinks.concat(pageLinks.slice(current_page - 2, current_page + 1))
      visibleLinks.push({ url: null, label: '...', active: false })
    }

    // Always include last page
    if (!visibleLinks.includes(pageLinks[pageLinks.length - 1])) {
      visibleLinks.push(pageLinks[pageLinks.length - 1])
    }
  }

  return (
    <Pagination>
      <PaginationContent className="w-full justify-between px-12 sm:justify-center sm:space-x-4 sm:px-0">
        <PaginationItem>
          <PaginationPrevious
            href="#"
            className="rounded-xl bg-muted/50 text-muted-foreground shadow shadow-foreground/10 transition-colors duration-75 hover:text-primary"
            onClick={(e) => {
              e.preventDefault()
              if (links[0].url) onPageChange(links[0].url)
            }}
          />
        </PaginationItem>
        {visibleLinks.map((link, index) => (
          <PaginationItem key={index} className="hidden">
            {link.url ? (
              <PaginationLink
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  onPageChange(link.url!)
                }}
                isActive={link.active}
                className={cn(
                  link.active &&
                    'border-0 bg-primary text-primary-foreground hover:bg-primary/90 hover:text-primary-foreground',
                  'rounded-lg tabular-nums text-muted-foreground'
                )}
              >
                {link.label}
              </PaginationLink>
            ) : (
              <PaginationEllipsis />
            )}
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationLink
            href="#"
            className="pointer-events-none select-none rounded-xl bg-muted/50 text-muted-foreground shadow shadow-foreground/10 transition-colors duration-75"
          >
            {current_page} / {total}
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationNext
            href="#"
            className="rounded-xl bg-muted/50 text-muted-foreground shadow shadow-foreground/10 transition-colors duration-75 hover:text-primary"
            onClick={(e) => {
              e.preventDefault()
              if (links[links.length - 1].url) onPageChange(links[links.length - 1].url as string)
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
