'use client'

import React from 'react'
import { cn } from '@/lib/utils'
import { Link } from '@inertiajs/react'

interface MarqueeCardProps {
  className?: string
  reverse?: boolean
  pauseOnHover?: boolean
  children?: React.ReactNode
  vertical?: boolean
  repeat?: number
  [key: string]: any
}

function MarqueeCard({
  className,
  reverse,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 8,
  ...props
}: MarqueeCardProps) {
  return (
    <div
      {...props}
      className={cn(
        'group flex overflow-hidden p-2 [--duration:40s] [--gap:1rem] [gap:var(--gap)]',
        {
          'flex-row': !vertical,
          'flex-col': vertical,
        },
        className
      )}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={cn('flex shrink-0 justify-around [gap:var(--gap)]', {
              'animate-marquee flex-row': !vertical,
              'animate-marquee-vertical flex-col': vertical,
              'group-hover:[animation-play-state:paused]': pauseOnHover,
              '[animation-direction:reverse]': reverse,
            })}
          >
            {children}
          </div>
        ))}
    </div>
  )
}

const reviews = [
  '/images/myroshan-cta/cta-balance.png',
  '/images/myroshan-cta/cta-credit-share.png',
  '/images/myroshan-cta/cta-free-usage.png',
  '/images/myroshan-cta/cta-language.png',
  '/images/myroshan-cta/cta-loyalty-spin.png',
  '/images/myroshan-cta/cta-signup.png',
  '/images/myroshan-cta/cta-special-offer.png',
  '/images/myroshan-cta/cta-usage.png',
]

const firstRow = reviews.slice(0, reviews.length / 2)
const secondRow = reviews.slice(reviews.length / 2)

const ReviewCard = ({ img }: { img: string }) => {
  return (
    <figure
      className={cn(
        'relative w-64 cursor-pointer overflow-hidden rounded-2xl p-0',
        'border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]',
        'dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]'
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="h-full w-full rounded-2xl" alt="MyRoshan CTA" src={img} />
      </div>
    </figure>
  )
}

export function MyRoshanCTA() {
  return (
    <section className="relative z-10 mx-auto mt-16 flex h-[500px] max-w-screen-xl overflow-hidden rounded-t-[2rem] sm:h-[400px] md:mb-10 xl:max-w-[1080px]">
      <div
        className="absolute inset-0 mx-auto h-[1000px] rotate-[15deg] scale-150 text-center transition-transform"
        dir="ltr"
      >
        <MarqueeCard reverse className="[--duration:40s]">
          {secondRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
        <MarqueeCard pauseOnHover className="[--duration:40s]">
          {firstRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
        <MarqueeCard reverse className="[--duration:40s]">
          {secondRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
        <MarqueeCard pauseOnHover className="[--duration:40s]">
          {firstRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
        <MarqueeCard reverse className="[--duration:40s]">
          {secondRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
        <MarqueeCard pauseOnHover className="[--duration:40s]">
          {firstRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
        <MarqueeCard reverse className="[--duration:40s]">
          {secondRow.map((review, key) => (
            <ReviewCard key={key} img={review} />
          ))}
        </MarqueeCard>
      </div>
      <div className="absolute inset-x-0 bottom-[22px] z-10 mx-auto max-w-screen-sm px-4 sm:px-0 lg:max-w-3xl">
        <div className="flex h-auto w-full flex-col items-start justify-start gap-4 rounded-[22px] bg-white/10 p-6 backdrop-blur-md sm:h-[148px] sm:flex-row sm:items-center sm:gap-x-6">
          <div className="flex items-center justify-start gap-x-3">
            <img src="/images/myroshan-log.png" className="" alt="My Roshan Logo" />
            <div className="flex flex-col items-start justify-center text-muted-foreground dark:text-white">
              <h1 className="text-2xl font-semibold sm:text-3xl">#MyRoshan</h1>
              <p className="text-[14px] font-medium leading-tight sm:text-[16px]">
                Download My Roshan App now to top-up, subscribe to your favorite internet and call
                plans or customize your own plan, and check your real-time usage.
              </p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-center gap-2 sm:w-[400px] sm:flex-col">
            <a
              href="https://apps.apple.com/pk/app/my-roshan/id1512175766"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="/images/appstore.png"
                alt="My Roshan AppStore Link"
                className="h-[44px] w-[154px]"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=af.roshan.myroshan&hl=en"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="/images/playstore.png"
                alt="My Roshan PlayStore Link"
                className="h-[44px] w-[154px]"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-90% dark:to-background"></div>
    </section>
  )
}
