import type React from 'react'
import { useEffect } from 'react'

const GlobalImagePreloadEffect: React.FC = () => {
  useEffect(() => {
    const loadImage = (img: HTMLImageElement) => {
      const placeholder = document.createElement('div')
      placeholder.className =
        'absolute inset-0 bg-gray-200 animate-pulse transition-all rounded-inherit'
      img.parentNode?.insertBefore(placeholder, img)

      img.style.opacity = '0'
      img.style.transition = 'opacity 300ms ease-in-out'

      if (img.complete) {
        onImageLoaded(img, placeholder)
      } else {
        img.onload = () => onImageLoaded(img, placeholder)
      }
    }

    const onImageLoaded = (img: HTMLImageElement, placeholder: HTMLDivElement) => {
      img.style.opacity = '1'
      placeholder.style.opacity = '0'
      setTimeout(() => placeholder.remove(), 500)
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage(entry.target as HTMLImageElement)
            observer.unobserve(entry.target)
          }
        })
      },
      {
        rootMargin: '50px',
      }
    )

    document.querySelectorAll('img').forEach((img) => {
      if (!img.complete) {
        observer.observe(img)
      } else {
        loadImage(img)
      }
    })

    return () => observer.disconnect()
  }, [])

  return null
}

export default GlobalImagePreloadEffect
